<template>
<div class="row row-custom" v-if="Sale">
    <div class="col-md-8">
        <div class="card text-right">
        <div class="card-header">
            تفاصيل  التصنيف   
                 </div>
        <div class="card-body">
            <div class="row">   
             <table class="table">
                 <tr><td>حالة الطلب </td><td>:</td><td>{{Sale.status.name.ar}}</td></tr>
                 <tr><td>اسم الزبون </td><td>:</td><td>{{Sale.client.first_name}}</td></tr>
                 <tr><td>رقم الهاتف</td><td>:</td><td>{{Sale.client.phone}}</td></tr>
                 <tr><td>الدولة</td><td>:</td><td>{{Sale.client.country.name.ar}}</td></tr>
                 <tr><td>المدينة</td><td>:</td><td>{{Sale.client.city.name.ar}}</td></tr>
                 <tr><td>اقرب نقطه</td><td>:</td><td>{{Sale.client.near_place}}</td></tr>
                 <tr><td>اجمالي السعر</td><td>:</td><td>{{Sale.total_price}}</td></tr>
                 <tr><td>اجمالي السعر بعد الخصم</td><td>:</td><td>{{Sale.total_price_discount}}</td></tr>
                 <tr><td>اجمالي السعر بعد الضربيه</td><td>:</td><td>{{Sale.total_price_tax}}</td></tr>
                 <tr><td>اجمالي السعر مع كود الخصم</td><td>:</td><td>{{Sale.total_price_code_discount}}</td></tr>
             </table>

           <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary" style="text-align: center;">
                  <h4 class="card-title ">قائمة المنتجات</h4>
                </div>
                <div class="card-body" style="text-align:center;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class=" text-primary">
                        <th>
                          #
                        </th>
                        <th>
                          اسم المنتج
                        </th>
                        <th>
                          الكمية
                        </th>
                        <th>
                          السعر
                        </th>
                        <th>
                          السعر بعد التخفيض
                        </th>
                        <th>
                          السعر مع الضريبة
                        </th>
 

                      </thead>
                      <tbody>
                        <tr v-for="(sale,index) in Sale.ProductsSale" :key="index">
                          <td>
                            {{index+1}}
                          </td>
                          <td>
                            {{sale.product.name.ar}}
                          </td>
                          <td>
                            {{sale.quantity}}
                          </td>
                          <td>
                            {{sale.selling_price}}
                          </td>
                          <td>
                            {{sale.selling_price_after_discount}}
                          </td>
                          <td>
                            {{sale.selling_price_after_tax}}
                          </td>

                        </tr>
                       </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            </div>    




        </div>

    </div>

    </div>
    
</div>

</template>

<script>
import {mapState,mapMutations} from 'vuex';
import store from '../../../store/index'
export default {
      computed:{
    ...mapState({
        Sale:state=>state.admin.sale.Sale.data,
        }),

      },
      methods:{
  
      },
     beforeRouteEnter(to,from,next){    
        const uuid = to.params.uuid;
        store.dispatch('admin/fetchSale', uuid).then((response) => {
            next(true);
        }).catch(error=>{
            next(false);
        })
    },
}
</script>

<style scoped>
.row-custom{
    justify-content: center;
}

.card .card-header {
    background: #16918b;
    color: white;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Tajawal';
}
.card .card-body{
    font-size: 14px;
    font-family: 'Tajawal';
    font-weight: 600;
}
</style>